@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.paper {
  height: 100px;
  background: white;
  padding: 20px;
}

.btn {
  margin: 10px 5px;
}

.loading{
  background: white;
  height: 100%;
}