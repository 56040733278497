@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.message{
  padding: 20px;
  font-size: 15px;
  margin-bottom: 20px;
  padding: 7px 9px;
  color: #289BA0;
  border: 1px solid #48ABB0;
}

.info {
  p{
    margin-bottom: 0px;
    margin-top: 10px;
  } 
}

.paper {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 25px 40px 30px 40px;
}

.title {
  font-weight: bold;
  margin-bottom: 10px;
}