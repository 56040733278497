@import "base.scss";

.module {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.cardDark {
  background-color: #EDEDED;
  justify-content: center;
  align-items: center;
  text-align: center;

  .text {
    padding: 0 15px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .link {
    font-size: 14px;
    padding: 10px 15px;
    background-color: white;
    border-radius: 4px;
    border: 1px dotted #143B51;
  }
}

.header {
  border: 1px solid #ddd;
  padding: 10px 10px;
  margin: 10px 20px 0px 20px;
  height: 45px;

  .downloadAll {
    float: right;
    margin-top: -5px;
    padding: 3px 12px;
    color: #909090;
    border: 1px solid #a0a0a0;

    i {
      color: #999;
    }

    &:hover {
      background: #f5f5f5;
    }
  }
}

.list {
  flex-grow: 1;
  padding: 12px;

  a {
    text-decoration: none;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-bottom: 1px solid #C6C6C6;

    &:hover {
      background-color: #F7F7F7;
    }

    .idContainer {
      margin-right: 21px;
      margin-left: 7px;
      text-align: center;

      .id {
        color: #1B9AAA;
        padding: 2px 5px;
        border-radius: 4px;
        border: 1px solid #1B9AAA;
        
        .info {
          margin-left: 5px;
          cursor: pointer;
          i {
            color: #1B9AAA;
          }
        }

        .edit {
          color: #999;
          margin-left: 8px;
          font-size: 0.7em;
          cursor: pointer;
        }
      }
    }

    .info {
      margin-right: 10px;
    }

    svg {
      fill: #D3D3D3;
      margin-left: -10px;
      margin-right: 5px;
    }
  }

  .info {
    .name {}

    .date {
      color: #A3AEBF;
      font-size: 14px;

      span {
        color: #A3AEBF;
      }
    }
  }
}

.options {
  margin-top: 5px;
}