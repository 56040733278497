@import "_mixins";

$dark-color: #002030;
$primary-color: #00454d;
$corpor-color: #007473;
$dot-color: #35e35da0;
$primary-light: #e6f5fc;
$secondary-color: #999;
$accent-color: #1BAAB0;
$accent-color-2: #e0c010;
$action-color: #f03296;

.flex {
  display: flex;
  justify-content: space-between;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.modalChild {
  padding: 30px;
  background-color: white;
}


h1 {
  font-size: 20px;
}

.declarationList {
  display: flex;
  flex-direction: column;

  button {
      width: 100%;
  }
}

.filterList {
  display: flex;
  flex-direction: column;

  button {
      padding: 10px;
      margin-bottom: 10px;
  }
}

.docList {
  margin-right: 40px;
}

.toRigth {
  margin-left: auto;
}

.calc {
  justify-content: center;
  margin: 20px 0;

  &:first-child {
      margin-top: 40px;
  }
}

/*=============================================================
CHEVEZ.css v1.0.0
Author: @Antony999k
=============================================================*/

/* Resete HTML
---------------------------------------------------------------*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin-bottom: 20px;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main {
  display: block;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  color: #1BA0D0;
}

li,
ul,
ol {
  list-style: none;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

input,
textarea {
  font-size: 16px;

  &::-webkit-input-placeholder {
      font-size: 16px;
  }

  &::-moz-placeholder {
      font-size: 16px;
  }

  &:-ms-input-placeholder {
      font-size: 16px;
  }

  &::placeholder {
      font-size: 16px;
  }
}

button {
  padding: 0;
  background: none;
}

button:hover,
a:hover {
  cursor: pointer;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


/* Global HTML
---------------------------------------------------------------*/
* {
  color: $primary-color;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

p,
a,
button {
  font-size: 16px;
}

button:focus {
  outline: 0;
}

/* Columns
---------------------------------------------------------------*/
.col2 {
  width: 15.66666666% !important;
}

.col2Full {
  width: 16.66666666% !important;
}

.col3 {
  width: 24% !important;
}

.col3Full {
  width: 25% !important;
}

.col4 {
  width: 32.33333333% !important;
}

.col4Full {
  width: 33.3333333333% !important;
}

.col5 {
  width: 40.66666% !important;
}

.col5Full {
  width: 41.66666% !important;
}

.col6 {
  width: 49% !important;
}

.col6Full {
  width: 50% !important;
}

.col7 {
  width: 57.3333% !important;
}

.col7Full {
  width: 58.3333% !important;
}

.col8 {
  width: 65.66666666666% !important;
}

.col8Full {
  width: 66.66666666666% !important;
}

.col9 {
  width: 74% !important;
}

.col9Full {
  width: 75% !important;
}

.col10 {
  width: 82.666666% !important;
}

.col10Full {
  width: 83.666666% !important;
}

.col12 {
  width: 100% !important;
}

/* Buttons Types
---------------------------------------------------------------*/
.btn {
  padding: 3px 12px;
  border-radius: 4px;
  color: $primary-color;
  margin-bottom: 0;

  * {
    color: $primary-color;
  }

  &:hover {
      text-decoration: none;
      color: $primary-color;

      * {
        color: $primary-color;
      }
  }
}

.btnRegular {
  background-color: #E6EBED;
  border: 1px solid #D5DBDA;

  &:hover {
      background-color: #CFD3D5;
      border: 1px solid #CFD3D5;
  }

  &:active {
      background-color: #B8BCBE;
      border: 1px solid #B8BCBE;
  }

  &:disabled,
  &[disabled] {
      background-color: #F0F3F4;
      border: 1px solid #F0F3F4;
      color: #9CA1A4;
      cursor: not-allowed;
  }
}

.btnOutline {
  background-color: none;
  border: 1px solid $primary-color;

  &:hover {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      color: white;

      * {
        color: white;
      }
  }

  &:active {
      background-color: #102F41;
      border: 1px solid #102F41;
      color: #CFD5D9;

      * {
        color: #CFD5D9;
      }
  }

  &:disabled,
  &[disabled] {
      background-color: #8A9DA8;
      border: 1px solid #8A9DA8;
      color: white;
      cursor: not-allowed;

      * {
        color: white;
      }
  }
}

.btnGreen {
  border: 1px solid #01C070;
  background-color: #00D080;
  color: white;

  *{
    color: white;
  }

  &:hover {
      background-color: #10D080;
      color: white;
      *{
        color: white;
      }
  }

  &:active {
      background-color: #00b566;
      border-color: #00894b;
      *{
        color: #00894b;
      }
  }

  .text {
      color: white;
  }

  .icon {
      font-size: 20px;

      path {
          color: white;
      }
  }
}

.btnOutlineGreen {
  border: 1px solid #00b566;
  color: #00b566;

  *{
    color: #00b566;
  }

  &:hover {
      background: #00b566;
      border: 1px solid #00b566;
      color: #fff;
      *{
        color: #fff;
      }
  }

  &:active {
      background: #00b566;
      border: 1px solid #00b566;
      color: #fff;
      *{
        color: #fff;
      }
  }

  &:disabled,
  &[disabled] {
      background-color: #8A9DA8;
      border: 1px solid #8A9DA8;
      color: white;
      cursor: not-allowed;
      *{
        color: #fff;
      }
  }
}

.btnLink {
  border-color: rgba(255, 255, 255, 0);

  &:hover {
      text-decoration: underline;
  }

  &:active {
      color: #102F41;
  }

  &:disabled,
  &[disabled] {
      color: #9CA1A4;
      cursor: not-allowed;
      text-decoration: none;
  }
}

/* Forms
---------------------------------------------------------------*/

.jumbotron {
  width: 100%;
  padding: 25px 25px;
  background: $primary-color;
  color: white;

  h4 {
      color: white;
      margin-bottom: 0px;
      font-size: 21px;
  }
}

.input,
.textarea {
  border-radius: 4px;
  border: 1px solid #626668;
  padding: 10px 13px;
  width: 100%;

  &::placeholder {
      color: #707070;
  }

  &:focus {
      border: solid 1px $primary-color;
      box-shadow: 0 0 3px $primary-color;
  }

  &:disabled,
  &[disabled] {
      color: #9CA1A4;
      border-color: #9CA1A4;
  }

  &:invalid {
      border-color: #EF476F;
      background-color: rgba(239, 71, 111, 0.05);
  }
}

/* Input File */
.inputfile {
  background-color: #E6EBED;
  padding: 20px 25px;
  border-radius: 4px;
  border: 1px dashed $primary-color;
  height: 76px;
  display: inline-block;

  &::-webkit-file-upload-button {
      visibility: hidden;
  }

  &::before {
      content: 'Add a File';
      display: inline-block;
      cursor: pointer;
      padding: 7px 25px;
      border-radius: 4px;
      border: 1px solid $primary-color;
  }
}

/* Select */
.selectContainer {
  position: relative;
  display: inline-block;

  .arrow {
      position: absolute;
      display: inline-block;
      top: 2px;
      right: 0px;
      pointer-events: none;

      svg {
          font-size: 36px;
      }
  }

  select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: white;
      border: 1px solid #626668;
      border-radius: 4px;
      padding: 10px 34px 10px 15px;
  }
}

/* Switch */
.switch {
  position: relative;
  display: block;
  width: 47px;
  height: 25px;

  input {
      opacity: 0;
      width: 0;
      height: 0;
  }

  input:checked+.slider {
      background-color: $primary-color;
  }

  input:focus+.slider {
      box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
  }

  .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #9CA1A4;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;

      &:before {
          position: absolute;
          content: "";
          height: 17px;
          width: 17px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 50%;
      }
  }
}

/* Checkbox */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 23px;
  line-height: 23px;

  &:hover input~.checkmark {
      background-color: #f4f4f4;
  }

  input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked~.checkmark {
          background-color: $primary-color;
      }

      &:checked~.checkmark:after {
          display: block;
      }
  }

  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 23px;
      width: 23px;
      background-color: white;
      border: 1px solid $primary-color;
      border-radius: 4px;

      &:after {
          content: "";
          position: absolute;
          display: none;
          left: 8px;
          top: 4px;
          width: 5px;
          height: 12px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
      }
  }
}

/* Radio Button */
.radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 21px;
  line-height: 21px;

  &:hover input~.checkmark {
      background-color: #f4f4f4;
  }

  input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked~.checkmark {
          background-color: white;
      }

      &:checked~.checkmark:after {
          display: block;
      }
  }

  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      width: 21px;
      background-color: white;
      border: 1px solid $primary-color;
      border-radius: 50%;

      &:after {
          content: "";
          position: absolute;
          display: none;
          left: 3px;
          top: 3px;
          width: 13px;
          height: 13px;
          background-color: $primary-color;
          border-radius: 50%;
      }
  }
}


/* Alerts
---------------------------------------------------------------*/
.alert {
  position: relative;
  border-radius: 4px;
  border: 1px solid #d6d8db;
  background-color: #e2e3e5;
  color: #383d41;
  padding: 15px 25px 15px 15px;
  display: inline-block;

  .btnClose {
      border: none;
      position: absolute;
      right: 5px;
      top: 5px;
  }
}

.alertError {
  background-color: #F7EBEE;
  border-color: #EF476F;
  color: #EF476F;

  .text {
      color: #EF476F;
  }

  .btnClose {
      .btn {
          path {
              color: #930a2a;
          }
      }
  }
}

.alertWarning {
  background-color: f7efe3;
  border-color: #FFB74D;
  color: #FFB74D;

  .text {
      color: #FFB74D;
  }

  .btnClose {
      .btn {
          path {
              color: #aa7427;
          }
      }
  }
}

.alertInfo {
  background-color: #E8F1F2;
  border-color: #1E919F;
  color: #1E919F;

  .text {
      color: #1E919F;
  }

  .btnClose {
      .btn {
          path {
              color: #116d77;
          }
      }
  }
}

.alertSucces {
  background-color: #E8F3F0;
  border-color: #1BB494;
  color: #1BB494;

  .text {
      color: #1BB494;
  }

  .btnClose {
      .btn {
          path {
              color: #149e80;
          }
      }
  }
}

/* Card
---------------------------------------------------------------*/
.cardNav {
  margin-bottom: -1px;
  position: relative;
  text-align: center;

  .item {
      background-color: white;
      border-radius: 4px 4px 0 0;
      padding: 9px 0;
      border-right: 1px solid #fafafa;
      border-left: 1px solid #fafafa;
      border-bottom: 0px;
      border-top: 0px;
      font-size: 15.5px;

      &:hover {
          background-color: #F0F3F4;
      }
  }

  .activeLink {
      .item {
          border-bottom: 2px solid $accent-color;
      }
  }
}

.card,
.cardReduce {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  background-color: white;

  p {
      margin: 0;
      color: #143B51;
  }
}

.cardHeader {
  display: flex;
  padding: 11px 20px;
  border-bottom: 1px solid #D0D0D0;
  align-items: center;

  h5 {
      font-size: 17px;
  }

  .title {
      margin: 0;
  }

  .btn {
      .icon {
          display: none;
      }
  }
}


.cardList,
.list {
  flex-grow: 1;
  a {
      text-decoration: none;
  }

  .active {
      .item {
          background-color: #fafafa;

          .idContainer .id {
              color: #fff;
              background: $accent-color;
          }
      }

  }

  .item {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      border-bottom: 1px solid #ddd;

      &:hover {
          background-color: #F7F7F7;
      }

      .idContainer {
          margin-right: 10px;
          text-align: center;

          .id {
              color: $accent-color;
              background: #fff;
              padding: 2px 5px;
              border-radius: 4px;
              border: 1px solid $accent-color;
          }
      }

      .info {
          margin-right: 10px;
      }

      svg {
          fill: #D3D3D3;
          margin-left: -10px;
          margin-right: 5px;
      }
  }

  .info {
      .name {}

      .date {
          color: #A3AEBF;
          font-size: 14px;
      }
  }
}

/* Tabs
---------------------------------------------------------------*/
.tab {
  .tablink {
      min-width: 120px;
      padding: 12px;

      &:hover {
          background-color: #F0F3F4;
      }
  }
}

.tab-primary {
  .tablink {
      border: none;
      border-bottom: 1px solid $primary-color;
      border-radius: 4px 4px 0px 0px;

      &.active {
          border: 1px solid $primary-color;
          border-bottom: none;
      }
  }
}

.tab-secondary {
  .tablink {
      border: 1px solid $primary-color;

      &:first-child {
          border-radius: 4px 0px 0px 4px;
      }

      &:last-child {
          border-radius: 0px 4px 4px 0px;
      }

      &:not(:first-child) {
          border-left: none;
      }

      &.active {
          background-color: $primary-color;
          color: white;
      }
  }
}

/* Tags
---------------------------------------------------------------*/
.tag {
  color: white;
  border-radius: 4px;
  background-color: $primary-color;
  padding: 5px;
  font-size: 12px;
  display: inline-block;
}

.tag-error {
  background-color: #EF476F;
}

.tag-warning {
  background-color: #FFB74D;
}

.tag-info {
  background-color: $accent-color;
}

.tag-succes {
  background-color: #1BB494;
}

/* Lists
---------------------------------------------------------------*/
.list-item {
  border: 1px solid #F0F3F4;
  border-radius: 4px;
  padding: 15px 20px 20px 20px;
  max-width: 300px;

  .list-item-top {
      display: flex;
      margin-bottom: 15px;

      .title {
          font-size: 20px;
          flex-grow: 1;
      }

      .date {
          display: flex;
          align-items: center;

          div {
              font-size: 12px;
              color: #626668;
              margin-right: 10px;

              &:last-child {
                  margin-right: 0px;
              }
          }
      }
  }

  .info {
      margin-bottom: 15px;
      overflow: hidden;
      max-height: 55px;
  }

  .see-more {
      text-align: right;

      button {
          color: $accent-color;
          border: none;
      }
  }
}

/* Navigation
---------------------------------------------------------------*/
.navigation-toolbar {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #707070;
  display: flex;
  background-color: white;
  align-items: center;
  padding: 0px 40px;
  position: relative;
  z-index: 10;

  .nav {
      ul {
          display: flex;

          li {
              margin-right: 50px;
          }
      }
  }

  .navigation-toolbar-right {
      display: flex;
      margin-left: auto;
      align-items: center;

      .searchForm {
          margin-right: 40px;
          position: relative;

          input {
              border-radius: 4px;
              border: 1px solid #626668;
              padding: 5px 40px 5px 10px;
              background-color: #F5F5F5;
          }

          .searchButton {
              color: #CBCBCB;
              position: absolute;
              right: 10px;
              top: 6px;

              &:hover {
                  cursor: pointer;
              }
          }
      }

      .profile {
          display: flex;
          align-items: center;

          .imgUser {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              position: relative;
              overflow: hidden;
              align-self: center;
              margin-right: 10px;

              img {
                  width: 100%;
                  display: inline;
                  margin: 0 auto;
                  height: auto;
              }
          }

          p {
              margin: 0;
          }
      }
  }
}


/* Tables
---------------------------------------------------------------*/
table,
.table {
  border-collapse: collapse;

  tr {
      border-bottom: 1px solid #E6EBED;

      &:first-child {
          border-width: 2px;
      }

      td,
      th {
          text-align: left;
          padding: 5px 10px;
      }
  }
}

.table-dark {
  tr {
      &:first-child {
          background-color: $primary-color;
          border: none;

          td,
          th {
              color: white;

              &:last-child {
                  border-radius: 0 4px 4px 0;
              }

              &:first-child {
                  border-radius: 4px 0px 0px 4px;
              }
          }
      }
  }
}

/* Drawer
---------------------------------------------------------------*/
.drawer {
  height: calc(100vh - 64px);
  padding: 25px 35px;
  background-color: $primary-color;
  width: 360px;
  display: flex;
  flex-direction: column;

  .btn-drawer {
      width: 100%;
      color: white;
      margin-bottom: 15px;

      &:hover {
          background-color: white;
          color: $primary-color;
      }

      &:last-child {
          margin-bottom: 0px;
      }
  }

  .drawer-top {
      flex-grow: 1;
  }

  .drawer-bottom {}
}

/* Drag and Drop
---------------------------------------------------------------*/
.drop-area {
  border: 1px dashed #ccc;
  background-color: #F0F3F4;
  border-radius: 4px;
  width: 380px;
  padding: 80px 60px;

  &.highlight {
      border-color: $primary-color;
  }

  .title {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 10px;
  }

  form {
      margin-bottom: 10px;
      text-align: center;

      #filesInput {
          display: none;
      }

      svg {
          font-size: 80px;
          margin-bottom: 20px;
      }
  }

  .gallery {
      margin-top: 10px;

      .img {
          width: 150px;
          margin-bottom: 10px;
          margin-right: 10px;
          vertical-align: middle;
      }
  }
}

/* Modal
---------------------------------------------------------------*/
.modal {
  width: 681px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.27);

  .modal-header {
      display: flex;
      align-items: center;
      background-color: $primary-color;
      padding: 30px;

      .title {
          color: white;
          flex-grow: 1;
          margin: 0;
      }

      button {
          border: none;
      }

      svg {
          fill: white;
      }
  }

  .modal-body {
      padding: 30px;

      .modal-body-paragraph {
          margin-bottom: 30px;

          &:last-child {
              margin-bottom: 0px;
          }

          .subtitle {
              margin-bottom: 15px;
          }

          p {
              margin: 0;
          }
      }
  }
}


/* Helpers
---------------------------------------------------------------*/
.emptyList {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 400px;
  padding: 70px;

  .icon {
      font-size: 100px;
      margin-bottom: 30px;

      path {
          color: #9ca0a8;
      }
  }

  p {
      color: #9ca0a8 !important;
  }
}