@import "base.scss";

.module {
  width: 100%;
}


.topnav {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0px 30px 0px 10px;
  justify-content: space-between;
  background-color: $primary-color;

  .navLeft {
    display: flex;
    align-items: center;

    .dots{
      width: 42px;
      height: 42px;
      margin-left: 5px;
      margin-right: 15px;
      $bg-color: $primary-color;
      $dot-size: 2px;
      $dot-space: 8px;
    
      background:
        linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
        linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
        $dot-color;
      background-size: $dot-space $dot-space;
    }
    

    .logo {
      width: 125px;
      padding-right: 30px;
      margin-right: 40px;
      border-right: 2px solid rgba(255, 255, 255, 0.2);

      img {
        width: 100%;
        filter: brightness(0) invert(1);
      }
    }

    h5 {
      font-size: 17.5px;
      margin-bottom: 2px;
      font-family: Geneva, sans-serif;
      color: white;

      span {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        margin-left: 3px;
      }
    }
  }

  .user {
    display: flex;
    align-items: center;
    cursor: pointer;

    .help {
      color: white;
      display: inline-block;
      cursor: pointer;
      font-size: 21px;
      margin-top: 21px;
      margin-right: 25px;
      color: rgba(255,255,255,0.85)
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 12px;
      border: 2px solid white;
    }

    p {
      margin: 0;
      color: white;
      min-width: 100px;
      line-height: 1.1em;

      span {
        font-size: 0.9em;
        color: rgba(255, 255, 255, 0.75);

        i {
          color: rgba(255, 255, 255, 0.75);
          margin-left: 5px;
        }
      }
    }
  }
}