@import "base.scss";

.module {
  width: 100%;
  height: 100%;
  padding: 10px;
}


.underConstruction{
  position: relative;
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 50px;
}

.underConstructionFore{
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.4);
  margin-top: -400px;
}

.statusContainer{
  width: 100%;
  height: 450px;
  border: 1px solid #e0e0e0;
  overflow: auto;
}

.status {
  position: relative;
  width: 550px;
  height:300px;
  top: 50px;
  left: 50%;
  margin-left: -275px;
  .message{
    text-align: center;
    font-size: 21px;
    margin-bottom: 24px;
    .icon {
      font-size: 54px;
      padding-bottom: 21px;
    }
    .sub {
      font-size: 0.85em;
      color: #777;
      margin-top: -1px;
    }
  }
}
.options {
  margin-top: 20px;
  display: inline-block;
}

.cardNav {
  text-align: left;
  min-height: 40px;
}

.item {
  width: 40%;
}

.btn {
  i {
    color: white;
    padding-right: 4px;
  }
}

.btnGrey{
  width: 100%;
  color: #888;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ccc;
  text-align: left;
  margin-bottom: 4px;
  i {
    color: #888
  }
}
.actionTitle{
  display: inline-block;
  color: #555;
  font-size: 14px;
  margin-bottom: 7px;
}

.executeCalcContainer{
  text-align: center;
}

.updateCalcContainer,
.exportCalcContainer{
  float: right
}

.updateCalcContainer {
  margin-left: 12px;
  margin-right: 12px;
}

.updateCalc .btn{
  color: #777;
  border: 1px solid #ccc;
  
  i {
    color: #777;
  }

  &:hover {
    background: #fafafa;
  }
}


.messageContainer, .warningContainer{
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  padding: 14px 16px;
  margin-bottom: 10px;
  width: 550px;
  font-size: 15px;
  text-align: left;
  hr{
    margin-top: 20px;
    margin-bottom: 10px;
  }
  small {
    font-size: 0.95;
  }
}

.messageContainer{
  width: 350px;
  hr{
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.messageContent>div{
  text-align: center;
}

.warningContainer{
  width: 550px;
}

.warningContent{
  max-height: 250px;
  overflow: auto;
}

.btnGreen{
  background: #186169;
  box-shadow: 0px 4px 15px rgba(17, 129, 124, 0.6);
  padding: 8px 15px;
  z-index: 99;
  border: none;
  width: 200px;
  text-align: center;
  border-radius: 0px;
}

.btnGreen:hover{
  background: #174a50;
  padding: 8px 15px;
  z-index: 99;
  border: none;
}

.uploa{
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  color: #1BAAB0;
  width: 250px;
  border: 1px solid #1BAAB0;
  i{
    color: #1BAAB0
  }
}