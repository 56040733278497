@import "base.scss";

.module {
    width: 100%;
    height: 100%;
}

.id {
    padding: 7px 11px !important;
    margin-right: 6px !important;
}

.btn {
    i {
        color: white;
        padding-right: 5px;
    }
}