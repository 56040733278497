@import "base.scss";

.module {
  width: 750px;
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
  border: 1px solid #ccc;
}

.document {
  width: 100%;
  height: auto;
}

.region {
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.page {
  border: none;
}