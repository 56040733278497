@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.paper {
  padding: 32px;
  background-color: #fff;

  .modalDeclaration {
    flex-grow: 1;

    .nav {
      button {
        &:first-child {
          border-radius: 4px 0px 0 4px;
        }

        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }


    .input {
      border-radius: 4px;
      border: 1px solid #CECECE;
      width: 100%;
      background-color: rgb(248, 248, 248);
      height: auto !important;
    }

    .dateInput {
      border-radius: 4px;
      border: 1px solid #CECECE;
      width: 100%;
      padding: 9px 10px;
      background-color: rgb(248, 248, 248);
      height: auto !important;
    }

    .descripción {
      padding: 10px;
      border-radius: 4px;
      width: 100%;
      height: 120px;
      border: 1px solid #CECECE;
      background-color: rgb(248, 248, 248);
      margin-bottom: 10px;
      flex-grow: 1;
    }

    .select {
      height: 44px;
      width: 100%;
      border: 1px solid #CECECE;
    }

    .modalError {
      font-size: 11px;
      color: #f45353;
    }

    .flexFill {
      flex-grow: 1;
    }
  }

  .fullBtn {
    width: 100%;
  }

  .jump {
    margin-bottom: 10px;
  }

  .marginRight {
    margin-right: 10px;
  }
}

.addClient {
  border: 1px solid #ddd;
  color: #777;
  padding: 4px 10px;
  width: auto;
  cursor: pointer;

  i {
    color: #909090;
  }
}