@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.jumbotron {
  padding: 11px 25px;
  background-color: #007473;

  h4 {
    font-size: 16px;
  }
}

.content {
  background: #fff;
  width: 100%;
  height: 580px;
  display: flex;
}

.table {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-x: scroll;

  .notice {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 7px 9px;
    margin-bottom: 10px;
    border: 1px solid #48ABB0;
  }

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  td {
    width: auto;
    line-height: 1.2em;
    cursor: pointer;

    span {
      width: auto;
      font-size: 14px;
    }

    textarea {
      width: 100%;
      height: 100%;
      font-size: 12.5px;
      min-height: 150px;
    }
  }

  td:hover {
    background-color: #ccc;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  tr:first-child {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #1B9AAA;

    span {
      color: white;
      font-weight: bold;
    }
  }

  .delCel {
    min-width: 10px !important;
    font-size: 12px;
  }

  .selectedRow {
    background: red;
  }
}

.largeImageContainer {
  position: fixed !important;
  z-index: 10000 !important;
  width: 55% !important;
  height: 75% !important;
  left: 25% !important;
  top: 70px !important;
  background: white;
}

.loading {
  width: 100%;
  height: 100%;
  background: #fff;
}

.csv {
  float: right;
  padding: 6px 12px;
  margin-top: -21px;
  margin-right: 4px;
}

.pdf {
  float: right;
  padding: 6px 12px;
  margin-top: -21px;
  margin-right: 4px;
  border: 1px solid #bbb;
}

.preview {
  width: 400px;
  height: 100%;
}