@import "base.scss";

$rh-clr-warning-bkg: rgba(254, 183, 76, 0.1);
$rh-clr-warning: rgba(254, 183, 76, 1);
$rh-clr-danger: rgba(239, 71, 111, 1);
$rh-clr-success: rgba(27, 180, 148, 1);
$rh-clr-primary: #007473;
$rh-clr-info: rgba(26, 155, 172, 1);
$rh-clr-info-l: rgba(231, 247, 244, 1);
$rh-clr-gray: rgba(156, 161, 164, 1);
$rh-clr-lightblue: rgba(230, 235, 237, 1);
$rh-clr-lightgray: #f0f3f4;
$rh-clr-opalina: #fef2f5;
$rh-clr-darkgray: rgba(112, 112, 112, 1);
$rh-clr-chevez: #007473;


.module {
  overflow-y: auto;
}

.title {
  font-weight: bold;
  font-size: 48px;
  color: $rh-clr-primary;
}

.button {
  padding: 10px;
  margin: 10px 4px;
  border: none;
  border-radius: 4px;
  background-color: $rh-clr-lightblue;
  &:hover {
    background-color: $rh-clr-info;
    color: white;
  }
}

.right {
  float: right;
}

.clickable {
  cursor: pointer;
}

.fullscreen {
  height: 100%;
  width: 100%;
}

.module {
  position: fixed;
  width: 100%;
  height: 100%;
}

.font {
  font-family: "Oxygen", sans-serif;
}

.linebreak {
  margin-top: 20px;
}

.break {
  margin-top: 20px;
}

.sectionColored {
  background-color: $rh-clr-lightblue;
}

.sectionBordered {
  border-radius: 4px;
  border: 1px solid $rh-clr-lightgray;
}

.listItem {
  box-sizing: border-box;
  background-color: inherit;
  color: $rh-clr-primary;
  border: 1px solid $rh-clr-primary;
  padding: 12px;
  &.listItemActive {
    background-color: $rh-clr-lightblue;
  }
}

/*
=======================Flex and Alignment=========================
*/

.flex {
  display: flex;
  justify-content: left;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

/*
=======================Buttons=========================
*/

.button {
  padding: 10px;
  margin: 10px 4px;
  border: none;
  border-radius: 4px;
  background-color: $rh-clr-lightblue;
  &:hover {
    background-color: $rh-clr-info;
    color: white;
  }

  &.buttonOutline {
    background-color: white;
    border: 1px solid $rh-clr-primary;
    color: $rh-clr-primary;
    &:hover {
      background-color: $rh-clr-primary;
      color: white;
    }
  }
}

.badge {
  background-color: $rh-clr-danger;
  color: white;
}

.txt {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 12px 12px;
  background-color: $rh-clr-lightblue;
  border: none;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  &:focus {
    border: 1px solid $rh-clr-info;
    box-shadow: 10px, 10px, 30px, #000;
  }
  &:hover {
    box-shadow: 10px, 10px, 30px, #000;
  }
}

.field {
  @extend .txt;
  padding: 0px;
}

.select {
  @extend .txt;
  font-size: 16px;
  padding: 8px;
}

.alert {
  padding: 12px;
  border: 1px solid $rh-clr-warning;
  background-color: $rh-clr-warning-bkg;
  color: $rh-clr-warning;
  border-radius: 4px;
  margin-bottom: 12px;
}

//Modificaciones de este diseño
