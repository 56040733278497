@import "base.scss";

.module {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    overflow: auto;
}

.idContainer{
    width:100%;
    .id{
        text-align: left;
        padding: 4px 12px !important;
    }
}

.active {
    .item {
        color: #fff;
        background: $accent-color;
        .year{
            color: rgba(255,255,255,0.6) !important; 
        }
    }
}

.item{
    padding-right: 10px !important;
    .year{
        color: #777; 
    }
}

.edit {
    padding: 7px 9px;
    i {
        color: #bbb;
    }
    &:hover{
        background-color: #e0e0e0;
        border-radius: 50%;
        i{
            color: #777;
        }
    }
}

@media (max-width: 1210px) {
    .card {
        .cardHeader {
            .btn {
                .text {
                    display: none;
                }

                .icon {
                    display: block;
                }
            }
        }
    }
}