@import "base.scss";



.header {
  padding: 0px 20px 8px 20px;
  margin: 10px 6px 0px 0px;

  select {
    background: white;
    padding: 4px 6px !important;
    border: 1px solid #d0d0d0;
    margin-top: - 7px;
  }
}

.content{
  height: 420px;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.sync {
  float: right;
  padding: 3px 9px;
  color: #777;
  border: 1px solid #ccc;

  i {
    margin-left: 7px;
    color: #777;
    font-size: 0.9em;
    margin-right: 2px;
  }
}

.csv {
  float: right;
  padding: 3px 10px;
  margin-right: 7px;
  i {
    color: white;
    margin-left: 7px;
    margin-right: 7px;
  }
}

.pages {
  border: 1px solid #ddd;
  padding: 2px 20px;
  margin: 10px 5px;
  text-align: center;

  .page {
    display: inline-block;
    border: 1px solid #ddd;
    padding: 3px 10px;
    margin: 5px;
    cursor: pointer;
  }
}