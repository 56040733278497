@import "base.scss";

.module {
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px;
  overflow: auto;
}

.cardReduce{
  min-height: 460px;
}