@import "base.scss";

.module {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #d5e5e7;
}

.home {
  padding: 14px 30px;
  flex-grow: 1;
}

.content {
  padding: 10px;
  margin-top: -20px;
  height: 600px;
}

.contentContainer{
  height: 600px;
  min-width: 77%;
  overflow: hidden;
}

.declarations {
  height: 600px;
  max-width: 20.7%;
  overflow: hidden;
}

.helpModal{
  text-transform: uppercase;
	font-weight: bold;
	border-radius: 25px;
	letter-spacing: 1px;
	background-color: #186169;
	padding: 18px 30px;
	position: fixed;
	bottom: 40px;
	right: 50px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 4px 15px rgba(17, 129, 124, 0.6);
	z-index: 99;
  	border: none;
	color: white;
	text-decoration: none;
}


.helpModal:hover {
	background-color:#174a50;
	transform: translateY(-7px);
	color: white;
	text-decoration: none;
}

@media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 14px;
		padding: 12px 20px;
		bottom: 20px;
		right: 20px;
	}
}
