@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.content {
  background: #fff;
  display: flex;
}

.module {
  th {
    color: white;
  }

  td,
  th {
    width: auto;
    border: 1px solid #ddd;
    padding: 8px;
  }

  td {
    line-height: 1.4em;

    span {
      width: auto;
      font-size: 14px;
    }

    select {
      background: #fff;
      width: 150px;
      border: 1px solid #a0a0a0;
    }
  }

  tr {
    td:nth-child(0),
    th:nth-child(0) {
      min-width: 34px;
    }

    td:nth-child(1),
    th:nth-child(1) {
      min-width: 450px;
    }

    td:nth-child(2),
    th:nth-child(2) {
      min-width: 110px;
    }

    td:nth-child(3),
    th:nth-child(3) {
      min-width: 130px;
    }

    td:nth-child(4),
    th:nth-child(4) {
      min-width: 70px;
      text-align: center;
    }

    td:nth-child(5),
    th:nth-child(5) {
      min-width: 420px;
    }

    td:nth-child(6),
    th:nth-child(6) {
      min-width: 340px;
    }
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  .editCel {
    min-width: 10px;
    font-size: 12px;

    a i {
      color: #1B9AAA;
    }
  }
}

.table {
  width: 100%;
  height: 100%;
  padding: 4px 20px;
}

.header {
  tr:first-child {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #1B9AAA;

    span {
      color: white;
      font-weight: bold;
    }
  }
}

.innerTable {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  background: #fff;
}

.csv {
  float: right;
  margin-top: -5px;
  padding: 4px 8px;
  margin-right: 15px;
  color: #888;
  border: 1px solid #a0a0a0;
}

.docDivider {
  font-weight: bold;
  font-size: 14px;
  width: 2100px;
  color: #48ABB0;
  border: 1px solid #48ABB0;
  padding: 2px 10px;
  margin-top: 25px;
  margin-bottom: 10px;

  i {
    color: #48ABB0;
    margin-left: 10px;
    cursor: pointer;
  }
}

.tableDivider {
  font-style: italic;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 2px;
  color: #888;
}