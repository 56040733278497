@import "base.scss";

.header {
  border: 1px solid #ddd;
  padding: 8px 20px;
  margin: 10px 20px 0px 20px;

  select {
    background: white;
    padding: 7px 5px !important;
    border: 1px solid #d0d0d0;
  }
}


.sync {
  float: right;
  margin-top: -6px;
  padding: 5px 8px;

  i {
    margin-left: 7px;
    color: white;
    font-size: 0.9em;
    margin-right: 7px;
  }
}

.csv {
  float: right;
  padding: 5px 10px;
  margin-right: 7px;
  color: #777;
  border: 1px solid #ccc;
  border-radius: 4px;

  i {
    color: #777;
  }

  &:hover {
    background: #fafafa;
  }
}

.pages {
  border: 1px solid #ddd;
  padding: 1px 20px;
  margin: 10px 5px;
  text-align: center;

  .page {
    display: inline-block;
    border: 1px solid #ddd;
    padding: 3px 10px;
    margin: 5px;
    cursor: pointer;
  }
}