@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.cardNav {
  text-align: right;
  height: 38px;
}

.banks {
  float: left;
  color: #777;
  margin-top: 2px;
  margin-left: 18px;
  select {
    margin-left: 12px;
    background: white;
    margin-top: 5px;
    padding: 3px 6px !important;
    border: 1px solid #d0d0d0;
  }
}

.cardReduce{
  border:none;
}

.item {
  width: 27%;
}

.call {
  width: 19%;
  margin-top: 4px;
  margin-right: 21px;

  i {
    color: white;
    padding-right: 7px;
  }
}

.formats{
  .btn {
    border: 1px solid #b0b0b0;
    font-style: italic;
    color: #999;
  &:hover{
    background: #f5f5f5;
  }
  }
}