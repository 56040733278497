@import "base.scss";

.module {
  width: 100%;
  padding-top: 3px;
}

.btn {
  i {
    padding-right: 4px;
    color: white;
  }
}

.tabnav {
  width: 100%;
  background-color: white;
  padding-top: 3px;

  li {
    list-style-type: none;
    padding: 8px 40px;
    // min-width: 195px;
    border-right: 1px solid #fafafa;
    border-left: 1px solid #fafafa;
    text-align: center;
  }

  ul {
    display: flex;
    justify-content: center;
    margin: 0px 0px 20px 0px;
    border-bottom: 1px solid #ddd;

    a {
      color: #143B51;
      margin: 0;
      font-size: 15.5px;
      background-color: white;

      &:hover {
        text-decoration: none;
        background-color: #F0F3F4;
      }
    }
  }

  .activeLink {
    border-bottom: 3px solid $accent-color;
  }
}

.content {
  padding: 10px;
  margin-top: -20px;
  height: 95%;
}

.contentContainer{
  height: 95%;
  min-width: 76.5%;
  overflow: hidden;
}