@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.jumbotron {
  background-color: #007473;
  padding: 18px 25px;
  color: white !important;
}

.jumbotronSep {
  background-color: #007473;
  padding: 1px 0;
  color: white !important;
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 25px 40px 30px 40px;
}

.multiFieldContainer {
  padding: 10px;
  border: 1px solid #cecece;
}

.paperLoading {
  padding: 32px;
  background-color: #fff;
  min-height: 100% !important;
  min-width: 100% !important;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  padding: 32px;
  padding-top: 10px;
  background-color: #fff;

  .modalDeclaration {
    flex-grow: 1;

    .input {
      border-radius: 4px;
      border: 1px solid #cecece;
      width: 100%;
      background-color: #fff;
      height: auto !important;
    }

    .select {
      height: 44px;
      width: 100%;
      padding: 10px 10px;
      border: 1px solid #cecece;
    }

    .flexFill {
      flex-grow: 1;
    }
  }

  .fullBtn {
    width: 100%;
  }

  .jump {
    margin-bottom: 15px;
  }

  .marginRight {
    margin-right: 10px;
  }
}

.btnExport {
  background: white;
  border: 1px solid #10d080;
  padding: 3px 12px;

  .text {
    color: #10C070;
    font-size: 15px;
  }
}

.step {

  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

.vStepper {
  padding-top: 20px;
  position: relative;
  /*   visibility: visible; */
}


/* regular step */
.circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px;
  /* +6 for border */
  height: 20px;
  display: inline-block;
}

.line {
  top: 40px;
  left: 8px;
  height: 100%;
  position: absolute;
  border-left: 3px solid #ccc;
}

.content {
  margin-left: 20px;
  padding-top: 20px;
  width: 100%;
}


.btnGrey {
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  color: #777;
}