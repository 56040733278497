@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.jumbotron {
  background-color: #007473;
}

.module {
  select {
    background: white;
    border: 1px solid #d0d0d0;
    width: 300px;
    height: 35px;
  }
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 25px 40px 30px 40px;
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  background: #fff;

  .status{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 40px;
    margin-left: -50px;
    text-align: center;
  }
}

.newDoc {
  padding: 3px 12px;
  margin: 0px;
  background: #fff;
}

.fil {
  display: inline;
  width: 350px;
}

.remove{
  float: right;
  margin-top: -25px; 
}

.btnDisabled{
  background: #f0f0f0;
}