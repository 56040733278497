@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.jumbotron {
  padding: 11px 25px;
  background-color: #007473;

  h4 {
    font-size: 16px;
  }
}

.content {
  background: #fff;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.scan {
  width: 70%;
  height: 100%;
  padding: 65px 20px 20px 20px;
  text-align: center;
  overflow: scroll;
  scroll-behavior: smooth;
  background: #fafafa;

  .imageBorder {
    border: 1px solid #d0d0d0;
    margin-bottom: 5px;
  }

  .imageWrapper,
  .image {
    width: 100%;
    max-width: 1000px;
    background: white;

    div[data-wrapper=wrapper] {
      z-index: 5;
    }
  }
}

.notice {
  position: fixed;
  font-size: 15px;
  width: 65.5%;
  top: 90px;
  padding: 8px 9px 8px 21px;
  margin: -7px 2px 10px 2px;
  background: white;
  text-align: left;
  border: 1px solid #d0d0d0;
  text-align: left;
  z-index: 10;

  span {
    padding-right: 5px;
    padding-left: 5px;
  }

  select {
    background: white;
  }

  .info {
    cursor: pointer;
    margin-left: 10px;
    padding: 2px;
  }

  .auto {
    position: fixed;
    padding: 4px 10px;
    border: 1px solid #d0d0d0;
    color: white;
    background: white;
    z-index: 4;
    margin-top: -5px;
    right: 33.7%;
    margin-right: 120px;
    font-size: 15px;
    color: #707070;
  }

  .save {
    position: fixed;
    padding: 4px 10px;
    border: 2px solid white;
    color: white;
    background: #00D080;
    z-index: 4;
    margin-top: -7px;
    right: 33.5%;
  }

  .missing {
    position: fixed;
    right: 32.5%;
    font-size: 13px;
    text-align: left;
    margin-top: 14px;
    width: 420px;
    border: 1px solid #d0d0d0;
    background: white;
    padding: 4px 0px 4px 5px;
    z-index: 4;

    > i {
      color: #a0a0a0 !important;
    }

    .missingItem {
      display: inline-block;
      width: 335px;
    }

    .goContainer {
      display: inline-block;
    }

    .go {
      display: inline-block;
      border: 1px solid #00C080;
      color: #00A070 !important;
      padding: 2px 5px 2px 9px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: #fafafa;
      }

      i {
        font-size: 11px;
        color: #00A070 !important;
      }
    }

    .count {
      color: #a0a0a0;
      font-size: 12px;
    }
  }
}
.see{
  display: inline-block;
  border: 1px solid #c0c0c0;
  padding: 2px 8px 2px 8px !important;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.formatPreview {
  position: fixed;
  top: 130px;
  width: 250px;
  left: 350px;
  z-index: 10;
  border: 1px solid #d0d0d0;
}


.regions {
  width: 30%;
  height: 100%;
  overflow: auto;

  h5 {
    padding-top: 20px;
    margin-bottom: 12px;
  }

  hr {
    margin: 0px;
  }

  .item {
    padding: 10px 20px;
  }

  .list .item p {
    margin-bottom: 4px;
  }

  .delete .text {
    color: #777;
  }

  .btn {
    padding: 4px 6px;
    margin: 0px 2px;
  }

  .update {
    padding: 2px 5px;
    margin-left: 8px;
  }
}

.options {
  float: right;
  padding: 0px 4px;
  cursor: pointer;
}

.numId {
  border: none;
  background: #00D080;
  color: white;
  font-size: 13px;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  border-radius: 50%;
}




.region {
  position: absolute;
  left: 50%;
  margin-left: -135px;
  bottom: -2em;
  background: white;
  z-index: 10;

  select {
    background: white;
    max-width: 250px;
  }

  .delete {
    padding: 1px 5px;
    border: 1px solid #ddd;
    background: #ffff;
  }
}

.region.saved {
  bottom: -0.8em;
  right: 0.5em;
  left: auto;
  width: 240px;

  .legend {
    font-size: 13px;
    padding: 2px 12px;
    margin-right: 5px;
    background: white;
    border: 1px solid #00D080;
  }

  .btn {
    padding: 2px 12px;
    font-size: 13px
  }
}

.loading {
  width: 100%;
  height: 100%;
  background: #fff;
}

.loading2 {
  width: 100%;
  height: 100%;
  background: #fff;
}

.download {
  position: relative;
  float: right;
  margin-top: -40px !important;
  margin-right: 10px !important;
  padding: 4px 8px;
  color: #909090;
  border: 1px solid #b0b0b0;

  i {
    color: #999;
  }

  &:hover {
    color: #999;
    background: #f0f0f0;
  }
}